import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import './App.css';
import { io } from 'socket.io-client';
import { get } from 'lodash';
import { apiCall, UrlBuilder } from './libs/apiHelper';
const baseAPI = 'http://167.99.154.155:3001';
const apis = {
  importElephanApi: baseAPI + '/elephant-video/import-videos',
  importAniNewsApi: baseAPI + '/aninews/import-videos',
  uploadApi: baseAPI + '/elephant-video/upload-videos',
};

const logTypes = {
  error: 'error',
  success: 'success',
  log: 'log',
  info: 'info',
};
var isUploading = false

const LogLine = ({ log }) => {
  const textColor = () => {
    switch (log.type) {
      case 'error': {
        return 'text-danger';
      }
      case 'success': {
        return 'text-success';
      }
      case 'info': {
        return 'text-info';
      }
      default: {
        return 'text-dark';
      }
    }
  };
  return (
    <div className={`d-block ${textColor()}`}>
      <span className="mr-1 text-dark">{log.time.toLocaleTimeString()}:</span>
      {log.text}
    </div>
  );
};
var socket = null;
var pageTries = 0;
var uploadTimeId = null
class MainApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [{ time: new Date(), text: 'App started!', type: 'info' }],
      importNextPage: 1,
      uploadingVideo: null,
      isStarted: true,
      isStartedImportElephant: true,
      isStartedImportAniNews: true,
      lastUpload: new Date(),
      isImportNewOnly: true,
      aniNewsToken: localStorage.getItem('aniNewsToken'),
    };
  }

  addLog = (text, type = 'log') => {
    const { logs } = this.state;
    logs.unshift({ time: new Date(), text, type });
    this.setState({ logs: logs });
  };

  componentDidMount() {
    setTimeout(() => {
      window.location.reload()
    }, 8 * 60 * 60 * 1000)
    if (!socket) {
      const { isStarted } = this.state
      socket = io(baseAPI);
      socket.off('upload-done');
      socket.on('upload-status', (payload) => {
        switch (payload.status) {
          case 'done': {
            isUploading = false
            this.addLog(
              `${get(payload, 'data.title')} is uploaded`,
              logTypes.success
            );
            if (isStarted) {
              this.uploadVideo();
            }

            break;
          }
          case 'error': {
            isUploading = false
            const title = get(payload, 'data.video.title');
            this.addLog(
              `${title} is get error: ${get(payload, 'data.err.message')}`,
              logTypes.error
            );
            if (
              !this.state.uploadingVideo ||
              title == this.state.uploadingVideo.title
            ) {

              if (isStarted) {
                this.uploadVideo();
              }
            }

            break;
          }
          default: {

          }
        }
      });
    }
    const { logs, uploadingVideo, isStarted, lastUpload } = this.state;
    // import elephant video thread
    setInterval(() => {
      this.importElephantVideoData();
    }, 10 * 60 * 1000);

    // import aninews video thread
    setInterval(() => {
      this.importAninewsVideoData();
    }, 30 * 60 * 1000);


    setInterval(() => {
      const diffMs = new Date() - this.state.lastUpload; // milliseconds between now & Christmas
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      if (this.state.isStarted && diffMins > 60) {
        window.location.reload()
      }
      if (this.state.isStarted && diffMins > 15) {
        isUploading = false
        this.addLog(`[FORE]Checking to upload video.....`, logTypes.error);
        this.uploadVideo(true);
      } else {
        this.addLog(
          `Upload video runing good, last uploaded: ${this.state.lastUpload && this.state.lastUpload.toLocaleTimeString()
          }`,
          logTypes.success
        );
      }
    }, 15 * 60 * 1000);
  }
  componentWillUnmount() {
    socket.off('upload-done');
  }

  uploadVideo = (isForce = false) => {
    if (this.state.isStarted) {
      this.addLog(`Checking to upload video.....`);

      if (isUploading && !isForce) return

      isUploading = true
      this.state.lastUpload = new Date();
      if (uploadTimeId) {
        clearTimeout(uploadTimeId)
      }
      uploadTimeId = setTimeout(() => {
        apiCall(apis.uploadApi, null, 'GET')
          .then((res) => {
            uploadTimeId = null
            if (res.data.video) {
              this.addLog(
                `"${res.data && res.data.video && res.data.video.title
                }" is uploading...`,
                logTypes.info
              );
              this.setState({ uploadingVideo: res.data && res.data.video });
            } else {
              isUploading = false
              setTimeout(() => {
                this.uploadVideo();
              }, 10 * 60 * 1000)
            }
          })
          .catch((error) => {
            isUploading = false
            uploadTimeId = null
            setTimeout(() => {
              this.uploadVideo();
            }, 5 * 60 * 1000)
            this.addLog(`Error:${JSON.stringify(error)}`, logTypes.error);
          });
      }, 1000)

    }
  };

  importAninewsVideoData = () => {
    const { isStartedImportAniNews, aniNewsToken } = this.state;

    if (isStartedImportAniNews) {

      if (aniNewsToken) {
        this.addLog(`[AniNews]Importing data...`);
        apiCall(UrlBuilder(apis.importAniNewsApi, { token: aniNewsToken }), null, 'GET')
          .then((res) => {
            this.addLog(`[AniNews]Data imported`, logTypes.success);
          })
          .catch((error) => {
            this.addLog(`[AniNews]Error:${JSON.stringify(error)}`, logTypes.error);
          });
      } else {
        this.addLog(`[AniNews]Please enter`, logTypes.error);
      }

    }
  };


  importElephantVideoData = () => {
    const { importNextPage, isStartedImportElephant, isImportNewOnly } = this.state;

    if (isStartedImportElephant) {
      this.addLog(`Importing data page ${importNextPage}`);
      apiCall(UrlBuilder(apis.importElephanApi, { page: importNextPage }), null, 'GET')
        .then((res) => {
          let nextPage = res.data ? res.data.nextPage : importNextPage;

          if (nextPage == importNextPage) {
            if (pageTries >= 1) {
              pageTries = 0;
              nextPage = 1;
            } else {
              pageTries += 1;
            }
          } else {
            pageTries = 0;
          }
          if (isImportNewOnly && nextPage > 100) {
            nextPage = 1;
            pageTries = 0;
            this.addLog(
              `Data is up to date, the next import will be run in next 7 minutes`,
              logTypes.success
            );
          } else {
            this.addLog(
              `the page ${importNextPage} has been imported, the page ${nextPage}(${pageTries}) will be import in next 7 minutes`,
              logTypes.success
            );
          }

          this.setState({
            importNextPage: nextPage,
          });
        })
        .catch((error) => {
          this.addLog(`Error:${JSON.stringify(error)}`, logTypes.error);
        });
    }
  };

  start = () => {
    this.setState({ isStarted: !this.state.isStarted }, this.uploadVideo);
  };
  startImportElephant = () => {
    this.setState(
      {
        isStartedImportElephant: !this.state.isStartedImportElephant,
        importNextPage: 1,
      },
      this.importElephantVideoData
    );
  };

  startImportAniNews = () => {
    this.setState(
      {
        isStartedImportAniNews: !this.state.isStartedImportAniNews
      },
      this.importAninewsVideoData
    );
  };

  isImportNewOnlyChange = (e) => {
    console.log(e.target.checked);
    this.setState({ isImportNewOnly: e.target.checked });
  };
  render() {
    const { logs, isStarted, isStartedImportElephant, isStartedImportAniNews, isImportNewOnly, aniNewsToken } = this.state;
    const showingLogs = logs.slice(0, 100).reverse();
    return (
      <Container className="h-100 w-100">
        <Card className="h-100">
          <CardHeader className="text-center">
            <span className="text-bold">Sivoo Video import from Elephant</span>
          </CardHeader>
          <CardBody className="d-flex flex-column h-100 p-1">
            <div className="d-flex flex-grow-1 border mb-2 flex-column overflow-auto">
              <div className="h-100 w-100">
                {showingLogs && showingLogs.map((log) => <LogLine log={log} />)}
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              <Button
                color={isStarted ? 'danger' : 'success'}
                onClick={this.start}
              >
                {isStarted ? 'Stop Upload' : 'Start Upload'}
              </Button>

              <div className="border d-flex flex-row mx-3">
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0 d-flex align-items-center">
                  <Label className="mr-sm-2">New Only</Label>
                  <Input
                    type="checkbox"
                    checked={isImportNewOnly}
                    onChange={this.isImportNewOnlyChange}
                  ></Input>
                </FormGroup>
                <Button
                  className="mx-2"
                  color={isStartedImportElephant ? 'danger' : 'success'}
                  onClick={this.startImportElephant}
                >
                  {isStartedImportElephant ? 'Stop Import Elephant' : 'Start Import Elephant'}
                </Button>
              </div>
              <div className="border d-flex flex-row mx-3">
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0 d-flex align-items-center">
                  <Label className="mr-sm-2">Ani token</Label>
                  <Input
                    type="text"
                    value={aniNewsToken}
                    onChange={(e) => { localStorage.setItem('aniNewsToken', e.target.value); this.setState({ aniNewsToken: e.target.value }) }}
                  ></Input>
                </FormGroup>
                <Button
                  className="mx-2"
                  color={isStartedImportAniNews ? 'danger' : 'success'}
                  onClick={this.startImportAniNews}
                  disabled={!aniNewsToken}
                >
                  {isStartedImportAniNews ? 'Stop Import AniNews' : 'Start Import AniNews'}
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={MainApp} />
      </Switch>
    </Router>
  );
}
